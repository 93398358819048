import { DragDropModule } from '@angular/cdk/drag-drop'
import { NgModule } from '@angular/core'
import { SharedModule } from 'src/app/shared/shared.module'
import { TeacherListComponent } from './containers/teacher-list/teacher-list.component'
import { ProfessorCvDialog } from './components/professor-cv/professor-cv.dialog';

@NgModule({
  declarations: [
    TeacherListComponent,
    ProfessorCvDialog,
  ],
  imports: [SharedModule, DragDropModule],
})
export class TeacherModule {}
