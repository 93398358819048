import {
  CdkDrag,
  CdkDropList,
  moveItemInArray,
  transferArrayItem
} from '@angular/cdk/drag-drop'
import {Component, ElementRef, OnInit, ViewChild, ViewEncapsulation} from '@angular/core'
import * as confetti from 'canvas-confetti';
import {interval, Subscription} from "rxjs";
import {ProfessorCvDialog} from "../../components/professor-cv/professor-cv.dialog";
import {MatDialog} from "@angular/material/dialog";

export interface Professor {
  id?: string;
  name: string;
  image: string;
  cv: string;
}

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'app-project',
  templateUrl: './teacher-list.component.html',
  styleUrls: ['./teacher-list.component.scss'],
  providers: [],
})

export class TeacherListComponent implements OnInit {
  @ViewChild('overlay') overlay;
  @ViewChild('pandemic') pandemic;
  @ViewChild('space') space;
  @ViewChild('climate') climate;

  PROXIMITY_DROP_ENABLED=false //TODO THIS FEATURES HAS BUGS
  KILL_CONFETTI_AFTER_SECONDS = 10;
  IMAGE_PATH = '/assets/icons/';
  subscription: Subscription;
  isGameOver = false;
  pandemicSlots = [[], [], []];
  spaceSlots = [[], [], []];
  climateSlots = [[], [], []];
  score = 0;
  professors = [
    {
      id: 'professor-1',
      image: `url(${this.IMAGE_PATH}professor-1.png)`,
      name: 'Βασιλική Καλογερά: LIGO colaboration*',
      cv: 'Αστροφυσικός, ηγετικό μέλος της *διεθνούς επιστημονικής συνεργασίας για την αναζήτηση βαρυτικών κυμάτων.'
    },
    {
      id: 'professor-2',
      image: `url(${this.IMAGE_PATH}professor-2.png)`,
      name: 'Kizzmekia Corbett: NIAID*',
      cv: 'Ερευνήτρια στο Vaccine Research Center του *Εθνικού Ινστιτούτου αλλεργίας και λοιμωδών νοσημάτων. Κεντρική φιγούρα στην επιστήμη των εμβολίων για τον κορονοϊό.'
    },
    {
      id: 'professor-3',
      image: `url(${this.IMAGE_PATH}professor-3.png)`,
      name: 'Μανώλης Κέλλης: Μ.Ι.Τ.',
      cv: 'Έχει εκδόσει τον πιο ακριβή γονιδιακό χάρτη για τον Κορονοϊού.'
    },
    {
      id: 'professor-4',
      image: `url(${this.IMAGE_PATH}professor-4.png)`,
      name: 'Χρήστος Κυρατσούς: Regeneron',
      cv: 'Επικεφαλής έρευνας μολυσματικών νόσων και τεχνολογιών φορέων της εταιρίας.'
    },
    {
      id: 'professor-5',
      image: `url(${this.IMAGE_PATH}professor-5.png)`,
      name: 'Amy Mainzer: NEO surveyor*',
      cv: 'Αστρονόμος στο *near earth object surveillance mission που θα\n' +
        'επιτηρεί το ηλιακό μας σύστημα για επικίνδυνους αστεροειδείς.'
    },
    {
      id: 'professor-6',
      image: `url(${this.IMAGE_PATH}professor-6.png)`,
      name: 'Susan Solomon: hlorofluorocarbon free radical reaction',
      cv: 'Ατμοσφαιρική Χημικός, με τους συναδέλφους της ήταν οι πρώτοι που πρότειναν τον παραπάνω μηχανισμό σαν αιτία της τρύπας του όζοντος στην Ανταρκτική.'
    },
    {
      id: 'professor-7',
      image: `url(${this.IMAGE_PATH}professor-7.png)`,
      name: 'Judith A. Curry: Κλιματολόγος',
      cv: 'Πρώην πρόεδρος του School of Earth and Atmospheric Sciences στο Ινστιτούτο Τεχνολογίας της Τζόρτζια.'
    },
    {
      id: 'professor-8',
      image: `url(${this.IMAGE_PATH}professor-8.png)`,
      name: 'Χρήστος Ζερεφός: NCAR*',
      cv: 'Μέλος της Ακαδημίας Αθηνών και διεθνής εμπειρογνώμων στις πλανητικές μεταβολές. Εργάσθηκε στο Εθνικό Κέντρο Ατμοσφαιρικής Έρευνας (*National Center for Atmospheric Research)'
    },
    {
      id: 'professor-9',
      image: `url(${this.IMAGE_PATH}professor-9.png)`,
      name: 'Χρύσα Κουβελιώτου: Bruno Rossi Prize 2003',
      cv: 'Αστροφυσικός, πρώην επιλεφαλής ερευνήτρια στο Διαστημικό Κέντρο Πτήσεων Μάρσαλ της NASA.'
    }
  ];
  gameAnswer = [
    {fieldId: 'pandemic', professorId: 'professor-2'},
    {fieldId: 'pandemic', professorId: 'professor-3'},
    {fieldId: 'pandemic', professorId: 'professor-4'},
    {fieldId: 'space', professorId: 'professor-1'},
    {fieldId: 'space', professorId: 'professor-5'},
    {fieldId: 'space', professorId: 'professor-9'},
    {fieldId: 'climate', professorId: 'professor-6'},
    {fieldId: 'climate', professorId: 'professor-7'},
    {fieldId: 'climate', professorId: 'professor-8'},
  ];
  FIELD_IDS = [
    'pandemic-0', 'pandemic-1', 'pandemic-2',
    'space-0', 'space-1', 'space-2',
    'climate-0', 'climate-1', 'climate-2'
  ];
  FIELD_IDS_AND_PROFESSORS_ID = [
    'pandemic-0', 'pandemic-1', 'pandemic-2',
    'space-0', 'space-1', 'space-2',
    'climate-0', 'climate-1', 'climate-2',
    'professors'
  ];
  fieldsLength = {pandemic: 0, space: 0, climate: 0};
  fieldsStatus = {pandemic: 'active', space: 'active', climate: 'active'};
  isDropEnabled = false;

  constructor(public dialog: MatDialog, private elem: ElementRef) {
  }

  ngOnInit() {
  }

  get isOnPandemic() {
    const placeHolder = document.getElementsByClassName("cdk-drag-preview")[0];
    const pandemic = this.pandemic._element.nativeElement;
    return this.doElementsCollide(placeHolder, pandemic);
  }

  get isOnSpace() {
    const placeHolder = document.getElementsByClassName("cdk-drag-preview")[0];
    const space = this.space._element.nativeElement;
    return this.doElementsCollide(placeHolder, space);
  }

  get isOnClimate() {
    const placeHolder = document.getElementsByClassName("cdk-drag-preview")[0];
    const climate = this.climate._element.nativeElement;
    return this.doElementsCollide(placeHolder, climate);
  }

  getBlendMode(slot) {
    return slot.length === 1 ? 'unset' : 'lighten';
  }

  isRightAnswer(containerId, previousContainerId, professorId) {
    return this.gameAnswer.findIndex(
      answer =>
        containerId.includes(answer.fieldId) &&
        !(previousContainerId.includes(answer.fieldId)) &&  // movement inside the same scientific field
        answer.professorId == professorId) !== -1;
  }

  isRightAnswerReverted(containerId, previousContainerId, professorId) {
    console.log('containerId ' + containerId);
    console.log('previousContainerId ' +previousContainerId);
    console.log('professorId ' + professorId);
    console.log('--------------------');
    return this.gameAnswer.findIndex(
      answer =>
        previousContainerId.includes(answer.fieldId) &&
        !(containerId.includes(answer.fieldId)) && // movement inside the same scientific field
        answer.professorId == professorId) !== -1;
  }

  handleScore(containerId, previousContainerId, professorId) {

    if (this.isRightAnswer(containerId, previousContainerId, professorId)) {
      this.score += 1;
    }

    if (this.isRightAnswerReverted(containerId, previousContainerId, professorId)) {
      this.score -= 1;
    }

    if (this.score == 9) {
      this.displaySuccessMessage();
    }
  }

  onlyOne(item: CdkDrag, list: CdkDropList) {
    return list.data?.length < 1;
  }

  displaySuccessMessage(): void {
    this.isGameOver = true;

    const myConfetti = confetti.create(this.overlay, {
      resize: true, // will fit all screen sizes,
      useWorker: true
    });

    function randomInRange(min, max) {
      return Math.random() * (max - min) + min;
    }

    const source = interval(1000);
    this.subscription = source.subscribe(val => {
      myConfetti({
        angle: randomInRange(55, 125),
        spread: randomInRange(50, 70),
        particleCount: randomInRange(50, 100),
        origin: {x: randomInRange(0, 1), y: 1}
      })
    });

    setTimeout(() => this.subscription.unsubscribe(), this.KILL_CONFETTI_AFTER_SECONDS * 1000)
  }

  updateFieldLength(fieldName: string, previousFieldName: string) {
    // moving image within the same field
    if (fieldName.includes(previousFieldName)) {
      return
    }
    (fieldName in this.fieldsLength) && this.fieldsLength[fieldName]++;
    (previousFieldName in this.fieldsLength) && this.fieldsLength[previousFieldName]--;
  }

  updateFieldStatus(fieldName: string, previousFieldName: string) {
    // moving image within the same field or is not in a field
    if (fieldName.includes(previousFieldName) || !(fieldName in this.fieldsStatus)) {
      return
    }

    if (this.fieldsLength[fieldName] === 3 && this.isAllAnswersCorrect()) {
      this.fieldsStatus[fieldName] = 'inactive';
      for (let field in this.fieldsStatus) {
        if (this.fieldsLength[field] === 0) {
          this.fieldsStatus[field] = 'active'
        }
      }
    }

    if (this.fieldsLength[fieldName] == 1) {
      for (let field in this.fieldsStatus) {
        if (fieldName !== field) {
          this.fieldsStatus[field] = 'inactive'
        }
      }
      this.fieldsStatus[fieldName] = 'active'
    }
  }

  isScientificFieldActive(scientificField: string) {
    return this.fieldsStatus[scientificField] !== 'inactive'
  }

  isAllAnswersCorrect() {
    let totalPlacedImages = 0;
    for (let field in this.fieldsLength) {
      totalPlacedImages += this.fieldsLength[field];
    }

    return totalPlacedImages === this.score;

  }

  openDialog(professor: Professor): void {
    this.dialog.open(ProfessorCvDialog, {
      data: {image: professor.image, name: professor.name, cv: professor.cv},
    });
  }

  drop(event) {
    if (!this.isDropEnabled) {
      return
    }
    this.deactivateDrop();
    const scientificField = event.container.id.slice(0, -2);

    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else if (this.isScientificFieldActive(scientificField)) {
      const professorId = event.item.element.nativeElement.id;
      const professor = this.professors.filter(prof => prof.id === professorId);

      transferArrayItem(event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex);

      const previousScientificField = event.previousContainer.id.slice(0, -2);
      const containerId = event.container.id;
      const previousContainerId = event.previousContainer.id;
      console.log('professorId in drop: ' + professorId);

      this.handleSuccessfulDrop(containerId, previousContainerId, scientificField, previousScientificField, professorId, professor);
    }
  }

  handleSuccessfulDrop(containerId,previousContainerId, scientificField, previousScientificField, professorId, professor){
    this.handleScore(containerId, previousContainerId, professorId);
    this.updateFieldLength(scientificField, previousScientificField);
    this.updateFieldStatus(scientificField, previousScientificField);
    containerId!=='professors' && this.openDialog(professor[0]);
  }

  proximityDrop(event) {
    if (!this.PROXIMITY_DROP_ENABLED) {
      return
    }
    if (this.isDropEnabled) {
      return
    }

    const professorId = event.source.element.nativeElement.id;
    const previousContainerId = event.source.dropContainer.id;
    const previousScientificField = previousContainerId.slice(0, -2);
    const professor = this.professors.filter(prof => prof.id === professorId);

    if (this.isOnPandemic && this.isScientificFieldActive('pandemic')) {
      const slot = this.moveProfessor(professorId, this.pandemicSlots);
      this.handleSuccessfulDrop(`pandemic-${slot}`, previousContainerId, 'pandemic', previousScientificField, professorId, professor);
    }
    else if (this.isOnSpace && this.isScientificFieldActive('space')) {
      const slot = this.moveProfessor(professorId, this.spaceSlots);
      this.handleSuccessfulDrop(`space-${slot}`, previousContainerId, 'space', previousScientificField, professorId, professor);
    }
    else if (this.isOnClimate && this.isScientificFieldActive('active')) {
      console.log('professorId in proximityDrop: ' + professorId);
      const slot = this.moveProfessor(professorId, this.climateSlots);
      this.handleSuccessfulDrop(`climate-${slot}`, previousContainerId, 'climate', previousScientificField, professorId, professor);
    }
  }

  moveProfessor(professorId, fieldArray) {
    const slot = this.getEmptySlot(fieldArray);
    if (slot === -1) {
      return
    }
    const professor = this.professors.filter(prof => prof.id === professorId);
    const professorIndex = this.professors.findIndex(prof => prof.id === professorId);
    fieldArray[slot] = professor;
    this.professors.splice(professorIndex, 1);
    return slot;
  }

  getEmptySlot(fieldArray) {
    return fieldArray.findIndex(slot => slot.length === 0);
  }

  activateDrop() {
    this.isDropEnabled = true;
  }

  deactivateDrop() {
    this.isDropEnabled = false;
  }

  doElementsCollide(el1, el2) {
    if (!el1 || !el2) {
      return
    }

    const el1Rect = el1.getBoundingClientRect();
    const el2Rect = el2.getBoundingClientRect();

    return !((el1Rect.bottom < el2Rect.top) ||
      (el1Rect.top > el2Rect.bottom) ||
      (el1Rect.right < el2Rect.left) ||
      (el1Rect.left > el2Rect.right))
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
