import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {Professor} from "../../containers/teacher-list/teacher-list.component";

@Component({
  selector: 'trello-professor-cv',
  templateUrl: './professor-cv.dialog.html',
  styleUrls: ['./professor-cv.dialog.scss']
})
export class ProfessorCvDialog implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<ProfessorCvDialog>,
    @Inject(MAT_DIALOG_DATA) public data: Professor,
  ) {}


  ngOnInit(): void {
  }

  close(): void {
    this.dialogRef.close();
  }
}
